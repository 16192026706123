.review-table{
	padding: 1.5625rem;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 0.0625rem solid #eee;
	height: auto;
    margin-bottom: 0;
	
	.disease {
		border-left: 0.0625rem solid #eee;
		padding-left: 1.25rem;
		@include respond('tab-port') {
			border-left: 0;
			padding-left: 0;	
			margin-right:0.625rem !important;
			margin-left:0;
		}
	}
	.star-review i{
	
		font-size:1.25rem;
		@include respond('tab-port') {
			font-size:216x;
		}
	}
	.media-body{
		p{
			color:#3e4954;
			font-size:1.125rem;
			line-height:1.5;
			@include respond('tab-port') {
				font-size:0.875rem;
			}
		}
	}
	.media-footer{
		min-width:31.25rem;
		@include respond('laptop') {
			min-width:18.75rem;
			margin-left:0.625rem;
		}
		@include respond('tab-port') {
			margin-left:0;
			margin-top:1.5625rem;
		}
	}
	img{
		@include respond('tab-port') {
			float:left;
			width: 5rem;
		}
	}
	.media{
		@include respond('tab-port') {
			display:block !important;
		}
		
	}
	@include respond('tab-land') {
		.custom-control{
			float:right;
		}
			
	}
	@include respond('tab-port') {
		padding: 0.9375rem;
	}
}
.review-tab.nav-pills{
	margin-bottom:0;
	li{
		display:inline-block;
		
		a.nav-link{
			color: #6b6b6b;
			background: #e9e9e9;
			box-shadow: none;
			border-radius: 0;
			font-weight: 600;
			font-size: 1rem;
			padding: 0.9375rem 2.5rem;
			margin-right: 0.0625rem;
			&.active{
				color: var(--primary);
				background: $white;
			}
			@include respond('tab-port') {
				font-size: 0.875rem;
				padding: 0.625rem 0.9375rem;
			}
		}
		&:first-child a.nav-link{
			border-radius:$radius 0 0 0;
		}
		&:last-child a.nav-link{
			border-radius:0 $radius 0 0;
		}
		
	}
}