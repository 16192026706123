@import "../../abstracts/variable";

.twitter-typeahead {
    width: 100%;

    // .form-control {
    //     background-color: $l-bg !important;
    // }

    .tt-dataset.tt-dataset-states {
        border: 0.0625rem solid $border;
    }

    .tt-menu {
        width: 100%;
        background-color: $white;

        .tt-suggestion {
            padding: 0.625rem;
            cursor: pointer;

            &:hover {
                background-color: var(--primary);
                color: $white;
            }
        }
    }
}

