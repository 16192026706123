%gradient_one {
    background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%,rgba(103, 25, 255, .85) 100%);
}

%gradient-1 {
	background: rgb(89,188,255);
	background: -moz-linear-gradient(45deg,  rgba(89,188,255,1) 21%, rgba(89,215,255,1) 100%); 
	background: -webkit-linear-gradient(45deg,  rgba(89,188,255,1) 21%,rgba(89,215,255,1) 100%); 
	background: linear-gradient(45deg,  rgba(89,188,255,1) 21%,rgba(89,215,255,1) 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#59bcff', endColorstr='#59d7ff',GradientType=1 ); 
}

%gradient-2 {
	background: rgb(74,217,148); 
	background: -moz-linear-gradient(45deg,  rgba(74,217,148,1) 0%, rgba(87,249,152,1) 100%);
	background: -webkit-linear-gradient(45deg,  rgba(74,217,148,1) 0%,rgba(87,249,152,1) 100%); 
	background: linear-gradient(45deg,  rgba(74,217,148,1) 0%,rgba(87,249,152,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4ad994', endColorstr='#57f998',GradientType=1 );
}

%gradient-3 {
	background: rgb(253,183,101);
	background: -moz-linear-gradient(45deg,  rgba(253,183,101,1) 0%, rgba(255,228,133,1) 100%); 
	background: -webkit-linear-gradient(45deg,  rgba(253,183,101,1) 0%,rgba(255,228,133,1) 100%);
	background: linear-gradient(45deg,  rgba(253,183,101,1) 0%,rgba(255,228,133,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdb765', endColorstr='#ffe485',GradientType=1 ); 
}

%gradient-4 {
	background: rgb(255,109,89); 
	background: -moz-linear-gradient(45deg,  rgba(255,109,89,1) 0%, rgba(255,213,132,1) 100%);
	background: -webkit-linear-gradient(45deg,  rgba(255,109,89,1) 0%,rgba(255,213,132,1) 100%);
	background: linear-gradient(45deg,  rgba(255,109,89,1) 0%,rgba(255,213,132,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff6d59', endColorstr='#ffd584',GradientType=1 ); 
}

%gradient-5 {
	background: -moz-linear-gradient(left,  rgba(246,148,58,1) 0%, rgba(255,209,48,0.74) 100%); 
	background: -webkit-linear-gradient(left,  rgba(246,148,58,1) 0%,rgba(255,209,48,0.74) 100%);
	background: linear-gradient(to right,  rgba(246,148,58,1) 0%,rgba(255,209,48,0.74) 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6943a', endColorstr='#bdffd130',GradientType=1 ); 
}

%gradient-6 {
	background: -moz-linear-gradient(45deg,  rgba(66,174,148,1) 25%, rgba(66,174,148,0.99) 29%, rgba(89,216,243,0.74) 100%); 
	background: -webkit-linear-gradient(45deg,  rgba(66,174,148,1) 25%,rgba(66,174,148,0.99) 29%,rgba(89,216,243,0.74) 100%); 
	background: linear-gradient(45deg,  rgba(66,174,148,1) 25%,rgba(66,174,148,0.99) 29%,rgba(89,216,243,0.74) 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#42ae94', endColorstr='#bd59d8f3',GradientType=1 );
}

%gradient-7 {
	background: -moz-linear-gradient(left,  rgba(150,88,199,1) 0%, rgba(237,60,177,0.74) 100%); 
	background: -webkit-linear-gradient(left,  rgba(150,88,199,1) 0%,rgba(237,60,177,0.74) 100%);
	background: linear-gradient(to right,  rgba(150,88,199,1) 0%,rgba(237,60,177,0.74) 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9658c7', endColorstr='#bded3cb1',GradientType=1 );
}

%gradient-8 {
    background: #F7B00F;
    background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
    background: -webkit-linear-gradient(top, #F7B00F 0%,#F25521 100%);
    background: linear-gradient(to bottom, #F7B00F 0%,#F25521 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F7B00F', endColorstr='#F25521',GradientType=1 );

    // background: linear-gradient(top, #f25521 0%, #f9c70a 100%);
}

%gradient-9 {
    background: #f31e7a !important;
    background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
    background: -webkit-linear-gradient(left, #f31e7a 0%,#fd712c 100%);
    background: linear-gradient(to right, #f31e7a 0%,#fd712c 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f31e7a', endColorstr='#fd712c',GradientType=1 );
}

%gradient-10 {
    background: #f25521 !important;
    background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: -webkit-linear-gradient(left, #f25521 0%,#f9c70a 100%);
    background: linear-gradient(to top, #f25521 0%,#f9c70a 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f25521', endColorstr='#f9c70a',GradientType=1 );
}

%gradient-11 {
    background: #3398fb;
    background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
    background: -webkit-linear-gradient(left, #3398fb 0%,#8553ee 100%);
    background: linear-gradient(to right, #3398fb 0%,#8553ee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3398fb', endColorstr='#8553ee',GradientType=1 );
}

%gradient-12 {
    background: #36e1b4;
    background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
    background: -webkit-linear-gradient(left, #36e1b4 0%,#11cae7 100%);
    background: linear-gradient(to right, #36e1b4 0%,#11cae7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36e1b4', endColorstr='#11cae7',GradientType=1 );
}

%gradient-13 {
    background: #ffbf31;
    background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
    background: -webkit-linear-gradient(left, #ffbf31 0%,#ff890e 100%);
    background: linear-gradient(to right, #ffbf31 0%,#ff890e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbf31', endColorstr='#ff890e',GradientType=1 );
}

%gradient-14 {
    background: #23bdb8;
    background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
    background: -webkit-linear-gradient(-45deg, #23bdb8 0%,#43e794 100%);
    background: linear-gradient(135deg, #23bdb8 0%,#43e794 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#23bdb8', endColorstr='#43e794',GradientType=1 );
}

%gradient-15 {
    background: #9a56ff;
    background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
    background: -webkit-linear-gradient(-45deg, #9a56ff 0%,#e36cd9 100%);
    background: linear-gradient(135deg, #9a56ff 0%,#e36cd9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9a56ff', endColorstr='#e36cd9',GradientType=1 );
}

%gradient-16 {
    background: #f48665;
    background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
    background: -webkit-linear-gradient(-45deg, #f48665 0%,#fda23f 100%);
    background: linear-gradient(135deg, #f48665 0%,#fda23f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f48665', endColorstr='#fda23f',GradientType=1 );
}

%gradient-17 {
    background: #e36cd9;
    background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
    background: -webkit-linear-gradient(-45deg, #e36cd9 0%,#fe60ae 100%);
    background: linear-gradient(135deg, #e36cd9 0%,#fe60ae 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e36cd9', endColorstr='#fe60ae',GradientType=1 );
}

%gradient-18 {
    background: #a15cff;
    background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
    background: -webkit-linear-gradient(left, #a15cff 0%,#ce82fd 100%);
    background: linear-gradient(to right, #a15cff 0%,#ce82fd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a15cff', endColorstr='#ce82fd',GradientType=1 );
}

%text-gradient {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
}
